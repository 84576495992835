import 'bootstrap'
import '../shared/nav/collapsing_nav'
import '../shared/phone'
import './nav'
import '../shared/buyers_page_personalizer'


import AddToCartForms from '../shared/add_to_cart_forms'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import CartNav from '../shared/nav/nav_cart'
import AddToCart from '../full-gpe/cart/add-to-cart'



document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  AddToCart.AddToCart()
})
